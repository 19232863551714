// extracted by mini-css-extract-plugin
export var button = "button-module--button--d3l5c";
export var button__burger = "button-module--button__burger--9cKHH button-module--button--d3l5c";
export var button__cta = "button-module--button__cta--lIITt";
export var button___outlined = "button-module--button___outlined--I6m24 button-module--button--d3l5c";
export var button___unelevated = "button-module--button___unelevated--CtxSg button-module--button--d3l5c";
export var button__hero = "button-module--button__hero--AST9D button-module--button___outlined--I6m24 button-module--button--d3l5c";
export var button___oversized = "button-module--button___oversized--IvDvL button-module--button--d3l5c";
export var button___primary = "button-module--button___primary--HAJm1";
export var button___secondary = "button-module--button___secondary--kYR7S";
export var button___white = "button-module--button___white--9G2yE";
export var button__social = "button-module--button__social--Wxlje button-module--button--d3l5c";
export var button__social___oversized = "button-module--button__social___oversized--l+5RA";